import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import ReactPlayer from 'react-player'

import api from '../../../services/api';

import imgEscola from '../../../assets/images/banner_escola.png';
import imgCursos from '../../../assets/images/banner_livros.png';
import imgFaq from '../../../assets/images/faq.png';
import imgFree from '../../../assets/images/banner_free.png';
import imgLabelFree from '../../../assets/images/label_free.png';
import imgBundleTitle from '../../../assets/images/bundle_title.png';
import reelsThumbnail from '../../../assets/images/reels_thumbnail.png';

import {
  mainStyle,
  cardStyle,
  titleBoxStyle,
  h2Style,
  imageBoxStyle,
  imgStyle,
  buttonBoxStyle,
  buttonStyle,
  infoBoxStyle,
  h1Style,
  cardBoxStyle,
  separatorStyle,
  imgFaqStyle,
  freeBannerStyle,
  freeLabelStyle,
  linkStyle,
  strongStyle,
  bundleBoxStyle,
  bundleTitleStyle,
  bundleContentStyle,
  bundleTextBoxStyle,
  bundleStrongStyle,
  bundlePStyle,
  bundleThumbnailStyle,
  bundleButtonBoxStyle,
  bundleButtonStyle,
  videoBoxStyle,
  reactPlayer,
  freeBoxStyle,
} from './styles';

const Main: React.FC = () => {
  const [ url, setUrl ] = useState<any>(null);

  useEffect(() => {  
    async function loadData() {
      try {
        const payload = {
          filename: 'apoio/reels_720p.mp4',
        }
        const response = await api.post('/public/getSignedUrl', payload);         
        if (response.data) {          
          setUrl(response.data.url);          
        }        
      } catch (error: any) {
                          
      }     
    }    
    loadData();    
  }, []);   
  
  return (
    <Box sx={mainStyle}>

      <Box sx={freeBoxStyle}>
        <Box component='a' sx={linkStyle} href='https://aluno.escoladenumerologia.com.br' target='_blank' rel='noreferrer noopener'>  
          <Box component='img' sx={freeLabelStyle} src={imgLabelFree} alt='conteudo_gratuito'/>     
        </Box>
        <Box component='a' sx={linkStyle} href='https://aluno.escoladenumerologia.com.br' target='_blank' rel='noreferrer noopener'>    
          <Tooltip 
            title="Cadastre-se e tenha acesso ao Material Gratuito exclusivo"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
          > 
            <Box component='img' sx={freeBannerStyle} src={imgFree} alt='conteudo_gratuito'/>
          </Tooltip>
        </Box>
      
        <Box component='a' sx={strongStyle} href='https://aluno.escoladenumerologia.com.br' target='_blank' rel='noreferrer noopener'>    
          <Box component='strong' >Cadastre-se e tenha acesso ao Material Gratuito exclusivo</Box>
        </Box>
      </Box>      

      <Box sx={separatorStyle}></Box>
      <Box sx={bundleBoxStyle}>
        <Box component='img' sx={bundleTitleStyle} src={imgBundleTitle} alt='super_curso'/>
        <Box sx={bundleContentStyle}>          
          <Box sx={videoBoxStyle}>
            {
              url ?
                <ReactPlayer
                  style={reactPlayer}
                  url={url}
                  width='100%'
                  height='100%'
                  controls
                  pip                             
                  playing            
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload'
                      }
                    }
                  }}
                />              
              :              
                <Box component='img' sx={bundleThumbnailStyle} src={reelsThumbnail}/> 
            }
          </Box>
          <Box sx={bundleTextBoxStyle}>
            <Box component='strong' sx={bundleStrongStyle}>PITAGÓRICA E CARMÁTICA</Box>
            <Box component='p' sx={bundlePStyle}> Descubra o poder dos números e transforme sua vida com o nosso 
              Super Curso de Numerologia Pitagórica e Carmática! Esta é sua chance de acessar um conhecimento 
              profundo e prático que irá guiar sua jornada de autoconhecimento e evolução espiritual.</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start', marginBottom: '1rem'}}>O que você vai encontrar neste curso completo:</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Curso completo de Numerologia Pitagórica</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Curso completo de Numerologia Carmática</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Programa de Cálculo do Mapa Numerológico</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Manual de Numerologia</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Aula de Mentoria ''A BASE DO CONHECIMENTO NUMEROLÓGICO''</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Aula de Mentoria ''NUMEROLOGIA PITAGÓRICA''</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Aula de Mentoria ''NUMEROLOGIA CARMÁTICA''</Box>
              <Box component='p' sx={bundlePStyle} style={{textAlign: 'start'}}>E mais:</Box>
              <Box component='p' sx={bundlePStyle}>💥 DESCONTO EXCLUSIVO no lançamento! Aproveite um preço 
                acessível e condições imperdíveis.</Box>
              <Box component='p' sx={bundlePStyle}>🌟 Garanta já o seu e inicie essa jornada de transformação com a Numerologia!</Box>
          </Box>          
        </Box>
        <Box sx={bundleButtonBoxStyle}>
          <Tooltip 
            title="Saiba mais sobre o Super Curso de Numerologia"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
          >
            <Link to='/super-curso' style={{ textDecoration: 'none' }}>
              <Box component='button' sx={bundleButtonStyle}>ACESSE E SAIBA MAIS</Box>
            </Link>
          </Tooltip>
        </Box>
      </Box>

      <Box sx={infoBoxStyle}>
        <Box sx={h1Style}>Cursos e Mentorias</Box>        
      </Box>
      <Box sx={separatorStyle}></Box>
      <Box sx={cardBoxStyle}>
        <Tooltip 
          title="Saiba mais sobre nossas Mentorias"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
        > 
          <Box sx={cardStyle}>
            <Box sx={titleBoxStyle}>
              <Box component='h2' sx={h2Style}>MENTORIAS</Box>
            </Box>
            <Link to='/mentorias' style={{ textDecoration: 'none' }}>
              <Box sx={imageBoxStyle}>
                <Box component='img' src={imgEscola} alt='mentorias' sx={imgStyle} />
              </Box>
            </Link>
            <Box sx={buttonBoxStyle}>
              <Link to='/mentorias' style={{ textDecoration: 'none' }}>
                <Box component='button' sx={buttonStyle}>ACESSAR</Box>
              </Link>
            </Box>
          </Box>
        </Tooltip>

        <Tooltip 
          title="Saiba mais sobre nossos Cursos para Iniciantes"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
        > 
          <Box sx={cardStyle}>
            <Box sx={titleBoxStyle}>
              <Box component='h2' sx={h2Style}>CURSOS</Box>
            </Box>
            <Link to='/cursos' style={{ textDecoration: 'none' }}>
              <Box sx={imageBoxStyle}>
                <Box component='img' src={imgCursos} alt='cursos' sx={imgStyle} />
              </Box>
            </Link>
            <Box sx={buttonBoxStyle}>
              <Link to='/cursos' style={{ textDecoration: 'none' }}>
                <Box component='button' sx={buttonStyle}>ACESSAR</Box>
              </Link>
            </Box>
          </Box>
        </Tooltip>
      </Box>

      <Tooltip 
        title="Veja respostas para as dúvidas mais frequentes"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
      >
        <Link to='/perguntas-mais-frequentes' style={{ textDecoration: 'none' }}>
          <Box component='img' sx={imgFaqStyle} src={imgFaq} alt='perguntas_frequentes'/>
        </Link>
      </Tooltip>

    </Box>
  );
}

export default Main;
