import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '225rem',
    sm: '255rem',
    md: '240rem',    
    lg: '205rem', 
  },  
  display: 'flex',
  flexDirection: {
    xs: 'column',    
  },
  justifyContent: {
    xs: 'space-evenly',
    lg: 'center',
  },      
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to right, var(--color-background-dark), var(--color-background-light))',
};

export const infoBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '8rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '2rem', 
};

export const separatorStyle: SxProps = {
  width: '100%',
  height: {
    xs: '0.5rem',
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-light), var(--color-background-login-dark))',  
};

export const cardBoxStyle: SxProps = {  
  height: {
    xs: '65rem',
    lg: '45rem',   
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  justifyContent: {
    xs: 'space-evenly',
    lg: 'center',
  },      
  alignItems: 'center', 
};

export const cardStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '26.87rem',
    sm: '30rem',
    lg: '35.7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
  marginLeft: {
    lg: '3rem',
  },
  marginRight: {
    lg: '3rem',
  },  
};

export const titleBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '5rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
};

export const imageBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '16.87rem',
    sm: '20rem',
    lg: '25.7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const buttonBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '5rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const h1Style: SxProps = {
  fontSize: {
    xs: '2.5rem',
    sm: '3.5rem',
  },
  fontWeight: 700,
  fontFamily: 'barlow',
  color: 'var(--color-text-brown)',
  //textShadow: '1px 1px var(--color-text-brown-light)',
};

export const h2Style: SxProps = {
  fontSize: {
    xs: '2rem',
    sm: '2.4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',
};

export const strongStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '40rem',
    lg: '50rem',
  },
  fontSize: {
    xs: '1.7rem',
    sm: '2rem',
    lg: '2.6rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  textAlign: 'center',
  color: 'var(--color-text-white)',
  marginTop: '0.5rem',
  marginBottom: '1rem',
  textDecorationLine: 'underline',  
};

export const imgStyle: SxProps ={
  width: '100%',
  height: '100%',
  cursor: 'pointer',
};

export const buttonStyle: SxProps = {
  width: {
    xs: '17rem',
    //sm: '25rem',
    //md: '18rem',
    //xl: '22rem',
  },
  height: {
    xs: '3rem',
    //sm: '4rem',
  },  
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: {
    xs: '1.5rem',
    //sm: '1.7rem',
    //md: '1.4rem',
    //xl: '1.6rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',    
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  '&:hover': { color: 'var(--color-button-text-hover)' },    
};

export const imgFaqStyle: SxProps = {
  width: {
    xs: '37rem',
    sm: '40rem',
    lg: '70rem',
  },
  height: {
    xs: '4.33rem',
    sm: '4.68rem', //8.54
    lg: '8.19rem',
  },
  cursor: 'pointer',
  marginTop: {
    lg: '2rem',
  },
};

export const freeBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: {
    xs: '2rem',
    lg: '5rem',
  },
};

export const freeBannerStyle: SxProps = {
  width: {
    xs: '35rem',
    sm: '50rem',
    lg: '75rem',
  },
  height: {
    xs: '7.77rem',
    sm: '11.11rem',
    lg: '16.66rem',
  },
  cursor: 'pointer',
  marginTop: {
    xs: '1rem',
    //sm: '1rem',
    //lg: '1rem',
  },
  /*marginBottom: {
    sm: '2rem',
    lg: '4rem',
  },*/  
};

export const freeLabelStyle: SxProps = {
  width: {
    xs: '28rem',
    sm: '40rem',
    lg: '60rem',
  },
  height: {
    xs: '1.86rem',
    sm: '2.66rem',
    lg: '3.99rem',
  },
  cursor: 'pointer',
  marginTop: {
    xs: '4rem',
    sm: '5rem',
    lg: '6rem',
  },
  /*marginBottom: {
    sm: '1rem',
    lg: '4rem',
  },*/  
};

export const linkStyle: SxProps = {
  textDecoration: 'none'
};

export const bundleBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    md: '60rem',
    lg: '120rem',
    xl: '140rem',
  },
  height: {
    xs: '115rem',
    sm: '135rem',
    md: '120rem',
    lg: '90rem',
  },
  marginBottom: {
    xs: '3rem',
  },
  marginTop: {
    xs: '1rem',
    lg: '3rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const bundleContentStyle: SxProps = {
  width: '100%',
  height: {
    xs: '100rem',
    sm: '120rem',
    md: '100rem',
    lg: '80rem',
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  justifyContent: {
    xs: 'center',
    lg: 'space-evenly',
  },
  alignItems: 'center',  
};

export const bundleTitleStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    md: '40rem',
    lg: '45rem',
  },
  height: {
    xs: '5rem',
    sm: '5.83rem',
    md: '6.66rem',
    lg: '7.5rem',
  },
  marginBottom: {
    xs: '1rem',
    sm: '2rem',
    md: '3rem',
  },
};

export const bundleTextBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    md: '60rem',
  },
  height: {
    xs: '60rem',
    sm: '80rem',
    md: '60rem',
    lg: '70.8rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: {
    xs: '2rem',
    sm: '2.5rem',
    lg: 0,
  }, 
};

export const bundleButtonBoxStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '5rem',
    lg: '8rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center', 
  marginTop: {
    lg: '2rem',
  },
};

export const bundleButtonStyle: SxProps = {
  width: {
    xs: '17rem',
    //sm: '25rem',
    //md: '18rem',
    //xl: '22rem',
    lg: '22rem',
  },
  height: {
    xs: '3rem',
    //sm: '4rem',
    lg: '4rem',
  },  
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: {
    xs: '1.5rem',
    //sm: '1.7rem',
    //md: '1.4rem',
    //xl: '1.6rem',
    lg: '1.8rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',    
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  '&:hover': { color: 'var(--color-button-text-hover)' },    
};

export const bundleStrongStyle: SxProps = {  
  fontSize: {
    xs: '2rem',
    sm: '2.2rem',
    md: '2.6rem',
    lg: '2.8rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  textAlign: 'center',
  fontStyle: 'italic',
  color: 'var(--color-text-white)',
  marginTop: '0.5rem',
  marginBottom: '2rem', 
};

export const bundlePStyle: SxProps = {
  width: '100%',
  fontSize: {
    xs: '1.5rem',
    sm: '1.8rem',
    lg: '2.2rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  textAlign: 'justify',
  color: 'var(--color-text-white)',
  marginBottom: '0.5rem',
};

export const bundleThumbnailStyle: SxProps = {
  width: {
    xs: '20rem',
    sm: '26rem',
    lg: '38rem',
  },
  height: {
    xs: '35.4rem',
    sm: '46.02rem',   
    lg: '67.52rem',    
  },
};

export const videoBoxStyle: SxProps = {
  position: 'relative', 
  width: {
    xs: '20rem',
    sm: '26rem',
    lg: '38rem',
  },
  height: {
    xs: '35.4rem',
    sm: '46.02rem',   
    lg: '67.52rem',    
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',    
};

export const reactPlayer: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,  
};
